/*!
 * Bootstrap v4.4.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter,  Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.alert-heading {
    color: inherit;
}

.alert-link {
    font-weight: 700;
}

.alert-dismissible {
    padding-right: 4rem;
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: .75rem 1.25rem;
    color: inherit;
}

.alert-primary {
    color: #004085;
    background-color: #cce5ff;
    border-color: #b8daff;
}

.alert-primary hr {
    border-top-color: #9fcdff;
}

.alert-primary .alert-link {
    color: #002752;
}

.alert-secondary {
    color: #383d41;
    background-color: #e2e3e5;
    border-color: #d6d8db;
}

.alert-secondary hr {
    border-top-color: #c8cbcf;
}

.alert-secondary .alert-link {
    color: #202326;
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}

.alert-success hr {
    border-top-color: #b1dfbb;
}

.alert-success .alert-link {
    color: #0b2e13;
}

.alert-info {
    color: #0c5460;
    background-color: #d1ecf1;
    border-color: #bee5eb;
}

.alert-info hr {
    border-top-color: #abdde5;
}

.alert-info .alert-link {
    color: #062c33;
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
}

.alert-warning hr {
    border-top-color: #ffe8a1;
}

.alert-warning .alert-link {
    color: #533f03;
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
}

.alert-danger hr {
    border-top-color: #f1b0b7;
}

.alert-danger .alert-link {
    color: #491217;
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe;
}

.alert-light hr {
    border-top-color: #ececf6;
}

.alert-light .alert-link {
    color: #686868;
}

.alert-dark {
    color: #1b1e21;
    background-color: #d6d8d9;
    border-color: #c6c8ca;
}

.alert-dark hr {
    border-top-color: #b9bbbe;
}

.alert-dark .alert-link {
    color: #040505;
}

*,
 :after,
 :before {
    box-sizing: border-box;
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff;
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0!important;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none;
}

address {
    font-style: normal;
    line-height: inherit;
}

address,
dl,
ol,
ul {
    margin-bottom: 1rem;
}

dl,
ol,
ul {
    margin-top: 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0;
}

dt {
    font-weight: 700;
}

dd {
    margin-bottom: .5rem;
    margin-left: 0;
}

blockquote {
    margin: 0 0 1rem;
}

b,
strong {
    font-weight: bolder;
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline;
}

sub {
    bottom: -.25em;
}

sup {
    top: -.5em;
}

a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

a:hover {
    color: #0056b3;
    text-decoration: underline;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none;
}

code,
kbd,
pre,
samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em;
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    -ms-overflow-style: scrollbar;
}

figure {
    margin: 0 0 1rem;
}

img {
    border-style: none;
}

img,
svg {
    vertical-align: middle;
}

svg {
    overflow: hidden;
}

table {
    border-collapse: collapse;
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #6c757d;
    text-align: left;
    caption-side: bottom;
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent;
}

label {
    display: inline-block;
    margin-bottom: .5rem;
}

button {
    border-radius: 0;
}

button:focus:not(:focus-visible) {
    outline: 0;
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

[role=button] {
    cursor: pointer;
}

select {
    word-wrap: normal;
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button;
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer;
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none;
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0;
}

textarea {
    overflow: auto;
    resize: vertical;
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0;
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal;
}

progress {
    vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto;
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button;
}

output {
    display: inline-block;
}

summary {
    display: list-item;
    cursor: pointer;
}

template {
    display: none;
}

[hidden] {
    display: none!important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2;
}

.h1,
h1 {
    font-size: 2.5rem;
}

.h2,
h2 {
    font-size: 2rem;
}

.h3,
h3 {
    font-size: 1.75rem;
}

.h4,
h4 {
    font-size: 1.5rem;
}

.h5,
h5 {
    font-size: 1.25rem;
}

.h6,
h6 {
    font-size: 1rem;
}

.lead {
    font-size: 1.25rem;
    font-weight: 300;
}

.display-1 {
    font-size: 6rem;
}

.display-1,
.display-2 {
    font-weight: 300;
    line-height: 1.2;
}

.display-2 {
    font-size: 5.5rem;
}

.display-3 {
    font-size: 4.5rem;
}

.display-3,
.display-4 {
    font-weight: 300;
    line-height: 1.2;
}

.display-4 {
    font-size: 3.5rem;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
}

.small,
small {
    font-size: 80%;
    font-weight: 400;
}

.mark,
mark {
    padding: .2em;
    background-color: #fcf8e3;
}

.list-inline,
.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-inline-item {
    display: inline-block;
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem;
}

.initialism {
    font-size: 90%;
    text-transform: uppercase;
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem;
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #6c757d;
}

.blockquote-footer:before {
    content: "\2014\A0"
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width:576px) {
    .container,
    .container-sm {
        max-width: 540px;
    }
}

@media (min-width:768px) {
    .container,
    .container-md,
    .container-sm {
        max-width: 720px;
    }
}

@media (min-width:992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 960px;
    }
}

@media (min-width:1200px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1140px;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
}

.no-gutters {
    margin-right: 0;
    margin-left: 0;
}

.no-gutters>.col,
.no-gutters>[class*=col-] {
    padding-right: 0;
    padding-left: 0;
}

.col,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2>* {
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3>* {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.row-cols-4>* {
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5>* {
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6>* {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.33333333%;
    max-width: 8.33333333%
}

.col-2 {
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.33333333%;
    max-width: 33.33333333%
}

.col-5 {
    flex: 0 0 41.66666667%;
    max-width: 41.66666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.33333333%;
    max-width: 58.33333333%
}

.col-8 {
    flex: 0 0 66.66666667%;
    max-width: 66.66666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%
}

.col-11 {
    flex: 0 0 91.66666667%;
    max-width: 91.66666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1;
}

.order-last {
    order: 13;
}

.order-0 {
    order: 0;
}

.order-1 {
    order: 1;
}

.order-2 {
    order: 2;
}

.order-3 {
    order: 3;
}

.order-4 {
    order: 4;
}

.order-5 {
    order: 5;
}

.order-6 {
    order: 6;
}

.order-7 {
    order: 7;
}

.order-8 {
    order: 8;
}

.order-9 {
    order: 9;
}

.order-10 {
    order: 10;
}

.order-11 {
    order: 11;
}

.order-12 {
    order: 12;
}

.offset-1 {
    margin-left: 8.33333333%
}

.offset-2 {
    margin-left: 16.66666667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333333%
}

.offset-5 {
    margin-left: 41.66666667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333333%
}

.offset-8 {
    margin-left: 66.66666667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333333%
}

.offset-11 {
    margin-left: 91.66666667%
}

@media (min-width:576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .row-cols-sm-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }
    .row-cols-sm-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }
    .row-cols-sm-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }
    .row-cols-sm-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }
    .row-cols-sm-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }
    .row-cols-sm-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }
    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-sm-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }
    .col-sm-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }
    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-sm-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }
    .col-sm-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }
    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-sm-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }
    .col-sm-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }
    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-sm-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }
    .col-sm-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }
    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-sm-first {
        order: -1;
    }
    .order-sm-last {
        order: 13;
    }
    .order-sm-0 {
        order: 0;
    }
    .order-sm-1 {
        order: 1;
    }
    .order-sm-2 {
        order: 2;
    }
    .order-sm-3 {
        order: 3;
    }
    .order-sm-4 {
        order: 4;
    }
    .order-sm-5 {
        order: 5;
    }
    .order-sm-6 {
        order: 6;
    }
    .order-sm-7 {
        order: 7;
    }
    .order-sm-8 {
        order: 8;
    }
    .order-sm-9 {
        order: 9;
    }
    .order-sm-10 {
        order: 10;
    }
    .order-sm-11 {
        order: 11;
    }
    .order-sm-12 {
        order: 12;
    }
    .offset-sm-0 {
        margin-left: 0;
    }
    .offset-sm-1 {
        margin-left: 8.33333333%
    }
    .offset-sm-2 {
        margin-left: 16.66666667%
    }
    .offset-sm-3 {
        margin-left: 25%
    }
    .offset-sm-4 {
        margin-left: 33.33333333%
    }
    .offset-sm-5 {
        margin-left: 41.66666667%
    }
    .offset-sm-6 {
        margin-left: 50%
    }
    .offset-sm-7 {
        margin-left: 58.33333333%
    }
    .offset-sm-8 {
        margin-left: 66.66666667%
    }
    .offset-sm-9 {
        margin-left: 75%
    }
    .offset-sm-10 {
        margin-left: 83.33333333%
    }
    .offset-sm-11 {
        margin-left: 91.66666667%
    }
}

@media (min-width:768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .row-cols-md-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }
    .row-cols-md-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }
    .row-cols-md-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }
    .row-cols-md-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }
    .row-cols-md-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }
    .row-cols-md-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }
    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-md-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }
    .col-md-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }
    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-md-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }
    .col-md-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }
    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-md-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }
    .col-md-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }
    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-md-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }
    .col-md-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }
    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-md-first {
        order: -1;
    }
    .order-md-last {
        order: 13;
    }
    .order-md-0 {
        order: 0;
    }
    .order-md-1 {
        order: 1;
    }
    .order-md-2 {
        order: 2;
    }
    .order-md-3 {
        order: 3;
    }
    .order-md-4 {
        order: 4;
    }
    .order-md-5 {
        order: 5;
    }
    .order-md-6 {
        order: 6;
    }
    .order-md-7 {
        order: 7;
    }
    .order-md-8 {
        order: 8;
    }
    .order-md-9 {
        order: 9;
    }
    .order-md-10 {
        order: 10;
    }
    .order-md-11 {
        order: 11;
    }
    .order-md-12 {
        order: 12;
    }
    .offset-md-0 {
        margin-left: 0;
    }
    .offset-md-1 {
        margin-left: 8.33333333%
    }
    .offset-md-2 {
        margin-left: 16.66666667%
    }
    .offset-md-3 {
        margin-left: 25%
    }
    .offset-md-4 {
        margin-left: 33.33333333%
    }
    .offset-md-5 {
        margin-left: 41.66666667%
    }
    .offset-md-6 {
        margin-left: 50%
    }
    .offset-md-7 {
        margin-left: 58.33333333%
    }
    .offset-md-8 {
        margin-left: 66.66666667%
    }
    .offset-md-9 {
        margin-left: 75%
    }
    .offset-md-10 {
        margin-left: 83.33333333%
    }
    .offset-md-11 {
        margin-left: 91.66666667%
    }
}

@media (min-width:992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .row-cols-lg-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }
    .row-cols-lg-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }
    .row-cols-lg-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }
    .row-cols-lg-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }
    .row-cols-lg-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }
    .row-cols-lg-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-lg-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }
    .col-lg-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-lg-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }
    .col-lg-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }
    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-lg-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }
    .col-lg-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }
    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-lg-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }
    .col-lg-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }
    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-lg-first {
        order: -1;
    }
    .order-lg-last {
        order: 13;
    }
    .order-lg-0 {
        order: 0;
    }
    .order-lg-1 {
        order: 1;
    }
    .order-lg-2 {
        order: 2;
    }
    .order-lg-3 {
        order: 3;
    }
    .order-lg-4 {
        order: 4;
    }
    .order-lg-5 {
        order: 5;
    }
    .order-lg-6 {
        order: 6;
    }
    .order-lg-7 {
        order: 7;
    }
    .order-lg-8 {
        order: 8;
    }
    .order-lg-9 {
        order: 9;
    }
    .order-lg-10 {
        order: 10;
    }
    .order-lg-11 {
        order: 11;
    }
    .order-lg-12 {
        order: 12;
    }
    .offset-lg-0 {
        margin-left: 0;
    }
    .offset-lg-1 {
        margin-left: 8.33333333%
    }
    .offset-lg-2 {
        margin-left: 16.66666667%
    }
    .offset-lg-3 {
        margin-left: 25%
    }
    .offset-lg-4 {
        margin-left: 33.33333333%
    }
    .offset-lg-5 {
        margin-left: 41.66666667%
    }
    .offset-lg-6 {
        margin-left: 50%
    }
    .offset-lg-7 {
        margin-left: 58.33333333%
    }
    .offset-lg-8 {
        margin-left: 66.66666667%
    }
    .offset-lg-9 {
        margin-left: 75%
    }
    .offset-lg-10 {
        margin-left: 83.33333333%
    }
    .offset-lg-11 {
        margin-left: 91.66666667%
    }
}

@media (min-width:1200px) {
    .col-xl {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }
    .row-cols-xl-1>* {
        flex: 0 0 100%;
        max-width: 100%
    }
    .row-cols-xl-2>* {
        flex: 0 0 50%;
        max-width: 50%
    }
    .row-cols-xl-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }
    .row-cols-xl-4>* {
        flex: 0 0 25%;
        max-width: 25%
    }
    .row-cols-xl-5>* {
        flex: 0 0 20%;
        max-width: 20%
    }
    .row-cols-xl-6>* {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }
    .col-xl-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }
    .col-xl-1 {
        flex: 0 0 8.33333333%;
        max-width: 8.33333333%
    }
    .col-xl-2 {
        flex: 0 0 16.66666667%;
        max-width: 16.66666667%
    }
    .col-xl-3 {
        flex: 0 0 25%;
        max-width: 25%
    }
    .col-xl-4 {
        flex: 0 0 33.33333333%;
        max-width: 33.33333333%
    }
    .col-xl-5 {
        flex: 0 0 41.66666667%;
        max-width: 41.66666667%
    }
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
    .col-xl-7 {
        flex: 0 0 58.33333333%;
        max-width: 58.33333333%
    }
    .col-xl-8 {
        flex: 0 0 66.66666667%;
        max-width: 66.66666667%
    }
    .col-xl-9 {
        flex: 0 0 75%;
        max-width: 75%
    }
    .col-xl-10 {
        flex: 0 0 83.33333333%;
        max-width: 83.33333333%
    }
    .col-xl-11 {
        flex: 0 0 91.66666667%;
        max-width: 91.66666667%
    }
    .col-xl-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
    .order-xl-first {
        order: -1;
    }
    .order-xl-last {
        order: 13;
    }
    .order-xl-0 {
        order: 0;
    }
    .order-xl-1 {
        order: 1;
    }
    .order-xl-2 {
        order: 2;
    }
    .order-xl-3 {
        order: 3;
    }
    .order-xl-4 {
        order: 4;
    }
    .order-xl-5 {
        order: 5;
    }
    .order-xl-6 {
        order: 6;
    }
    .order-xl-7 {
        order: 7;
    }
    .order-xl-8 {
        order: 8;
    }
    .order-xl-9 {
        order: 9;
    }
    .order-xl-10 {
        order: 10;
    }
    .order-xl-11 {
        order: 11;
    }
    .order-xl-12 {
        order: 12;
    }
    .offset-xl-0 {
        margin-left: 0;
    }
    .offset-xl-1 {
        margin-left: 8.33333333%
    }
    .offset-xl-2 {
        margin-left: 16.66666667%
    }
    .offset-xl-3 {
        margin-left: 25%
    }
    .offset-xl-4 {
        margin-left: 33.33333333%
    }
    .offset-xl-5 {
        margin-left: 41.66666667%
    }
    .offset-xl-6 {
        margin-left: 50%
    }
    .offset-xl-7 {
        margin-left: 58.33333333%
    }
    .offset-xl-8 {
        margin-left: 66.66666667%
    }
    .offset-xl-9 {
        margin-left: 75%
    }
    .offset-xl-10 {
        margin-left: 83.33333333%
    }
    .offset-xl-11 {
        margin-left: 91.66666667%
    }
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none;
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.form-control::-webkit-input-placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1;
}

input[type=date].form-control,
input[type=datetime-local].form-control,
input[type=month].form-control,
input[type=time].form-control {
    -webkit-appearance: none;
    appearance: none;
}

select.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff;
}

.form-control-file,
.form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px);
    font-size: .875rem;
    line-height: 1.5;
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .375rem 0;
    margin-bottom: 0;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
}

.form-control-plaintext.form-control-lg,
.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0;
}

.form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}

select.form-control[multiple],
select.form-control[size],
textarea.form-control {
    height: auto;
}

.form-group {
    margin-bottom: 1rem;
}

.form-text {
    display: block;
    margin-top: .25rem;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
}

.form-row>.col,
.form-row>[class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #6c757d;
}

.form-check-label {
    margin-bottom: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem;
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0;
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #28a745;
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(40, 167, 69, .9);
    border-radius: .25rem;
}

.form-row>.col>.valid-tooltip,
.form-row>[class*=col-]>.valid-tooltip {
    left: 5px;
}

.is-valid~.valid-feedback,
.is-valid~.valid-tooltip,
.was-validated :valid~.valid-feedback,
.was-validated :valid~.valid-tooltip {
    display: block;
}

.form-control.is-valid,
.was-validated .form-control:valid {
    border-color: #28a745;
    padding-right: calc(1.5em + .75rem)!important;
    background-image: url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.was-validated select.form-control:valid,
select.form-control.is-valid {
    padding-right: 3rem!important;
    background-position: right 1.5rem center;
}

.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
}

.custom-select.is-valid,
.was-validated .custom-select:valid {
    border-color: #28a745;
    padding-right: calc(.75em + 2.3125rem)!important;
    background: url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
}

.custom-select.is-valid:focus,
.was-validated .custom-select:valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.form-check-input.is-valid~.form-check-label,
.was-validated .form-check-input:valid~.form-check-label {
    color: #28a745;
}

.form-check-input.is-valid~.valid-feedback,
.form-check-input.is-valid~.valid-tooltip,
.was-validated .form-check-input:valid~.valid-feedback,
.was-validated .form-check-input:valid~.valid-tooltip {
    display: block;
}

.custom-control-input.is-valid~.custom-control-label,
.was-validated .custom-control-input:valid~.custom-control-label {
    color: #28a745;
}

.custom-control-input.is-valid~.custom-control-label:before,
.was-validated .custom-control-input:valid~.custom-control-label:before {
    border-color: #28a745;
}

.custom-control-input.is-valid:checked~.custom-control-label:before,
.was-validated .custom-control-input:valid:checked~.custom-control-label:before {
    border-color: #34ce57;
    background-color: #34ce57;
}

.custom-control-input.is-valid:focus~.custom-control-label:before,
.was-validated .custom-control-input:valid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.custom-control-input.is-valid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-valid~.custom-file-label,
.was-validated .custom-control-input:valid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:valid~.custom-file-label {
    border-color: #28a745;
}

.custom-file-input.is-valid:focus~.custom-file-label,
.was-validated .custom-file-input:valid:focus~.custom-file-label {
    border-color: #28a745;
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .25);
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545;
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #fff;
    background-color: rgba(220, 53, 69, .9);
    border-radius: .25rem;
}

.form-row>.col>.invalid-tooltip,
.form-row>[class*=col-]>.invalid-tooltip {
    left: 5px;
}

.is-invalid~.invalid-feedback,
.is-invalid~.invalid-tooltip,
.was-validated :invalid~.invalid-feedback,
.was-validated :invalid~.invalid-tooltip {
    display: block;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem)!important;
    background-image: url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem);
}

.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.was-validated select.form-control:invalid,
select.form-control.is-invalid {
    padding-right: 3rem!important;
    background-position: right 1.5rem center;
}

.was-validated textarea.form-control:invalid,
textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem);
}

.custom-select.is-invalid,
.was-validated .custom-select:invalid {
    border-color: #dc3545;
    padding-right: calc(.75em + 2.3125rem)!important;
    background: url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") right .75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3E%3C/svg%3E") center right 1.75rem/calc(.75em + .375rem) calc(.75em + .375rem) no-repeat;
}

.custom-select.is-invalid:focus,
.was-validated .custom-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.form-check-input.is-invalid~.form-check-label,
.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545;
}

.form-check-input.is-invalid~.invalid-feedback,
.form-check-input.is-invalid~.invalid-tooltip,
.was-validated .form-check-input:invalid~.invalid-feedback,
.was-validated .form-check-input:invalid~.invalid-tooltip {
    display: block;
}

.custom-control-input.is-invalid~.custom-control-label,
.was-validated .custom-control-input:invalid~.custom-control-label {
    color: #dc3545;
}

.custom-control-input.is-invalid~.custom-control-label:before,
.was-validated .custom-control-input:invalid~.custom-control-label:before {
    border-color: #dc3545;
}

.custom-control-input.is-invalid:checked~.custom-control-label:before,
.was-validated .custom-control-input:invalid:checked~.custom-control-label:before {
    border-color: #e4606d;
    background-color: #e4606d;
}

.custom-control-input.is-invalid:focus~.custom-control-label:before,
.was-validated .custom-control-input:invalid:focus~.custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.custom-control-input.is-invalid:focus:not(:checked)~.custom-control-label:before,
.custom-file-input.is-invalid~.custom-file-label,
.was-validated .custom-control-input:invalid:focus:not(:checked)~.custom-control-label:before,
.was-validated .custom-file-input:invalid~.custom-file-label {
    border-color: #dc3545;
}

.custom-file-input.is-invalid:focus~.custom-file-label,
.was-validated .custom-file-input:invalid:focus~.custom-file-label {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25);
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
}

.form-inline .form-check {
    width: 100%
}

@media (min-width:576px) {
    .form-inline label {
        justify-content: center;
    }
    .form-inline .form-group,
    .form-inline label {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }
    .form-inline .form-group {
        flex: 0 0 auto;
        flex-flow: row wrap;
    }
    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }
    .form-inline .form-control-plaintext {
        display: inline-block;
    }
    .form-inline .custom-select,
    .form-inline .input-group {
        width: auto;
    }
    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
    }
    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0;
    }
    .form-inline .custom-control {
        align-items: center;
        justify-content: center;
    }
    .form-inline .custom-control-label {
        margin-bottom: 0;
    }
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none;
    }
}

.btn:hover {
    color: #212529;
    text-decoration: none;
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .25);
}

.btn.disabled,
.btn:disabled {
    opacity: .65;
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
    color: #fff;
    background-color: #0069d9;
    border-color: #0062cc;
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5);
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #0062cc;
    border-color: #005cbf;
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 143, 255, .5);
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62;
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5);
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34;
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5);
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430;
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5);
}

.btn-info {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info.focus,
.btn-info:focus,
.btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #117a8b;
}

.btn-info.focus,
.btn-info:focus {
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5);
}

.btn-info.disabled,
.btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #10707f;
}

.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(58, 176, 195, .5);
}

.btn-warning {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning.focus,
.btn-warning:focus,
.btn-warning:hover {
    color: #212529;
    background-color: #e0a800;
    border-color: #d39e00;
}

.btn-warning.focus,
.btn-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5);
}

.btn-warning.disabled,
.btn-warning:disabled {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-warning:not(:disabled):not(.disabled).active,
.btn-warning:not(:disabled):not(.disabled):active,
.show>.btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #d39e00;
    border-color: #c69500;
}

.btn-warning:not(:disabled):not(.disabled).active:focus,
.btn-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(222, 170, 12, .5);
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130;
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d;
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5);
}

.btn-light {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light.focus,
.btn-light:focus,
.btn-light:hover {
    color: #212529;
    background-color: #e2e6ea;
    border-color: #dae0e5;
}

.btn-light.focus,
.btn-light:focus {
    box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5);
}

.btn-light.disabled,
.btn-light:disabled {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-light:not(:disabled):not(.disabled).active,
.btn-light:not(:disabled):not(.disabled):active,
.show>.btn-light.dropdown-toggle {
    color: #212529;
    background-color: #dae0e5;
    border-color: #d3d9df;
}

.btn-light:not(:disabled):not(.disabled).active:focus,
.btn-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(216, 217, 219, .5);
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124;
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d;
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5);
}

.btn-outline-primary {
    color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary.focus,
.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5);
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
    color: #007bff;
    background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 123, 255, .5);
}

.btn-outline-secondary {
    color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary.focus,
.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
    color: #6c757d;
    background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active,
.show>.btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(108, 117, 125, .5);
}

.btn-outline-success {
    color: #28a745;
    border-color: #28a745;
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-outline-success.focus,
.btn-outline-success:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
    color: #28a745;
    background-color: transparent;
}

.btn-outline-success:not(:disabled):not(.disabled).active,
.btn-outline-success:not(:disabled):not(.disabled):active,
.show>.btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(40, 167, 69, .5);
}

.btn-outline-info {
    color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info.focus,
.btn-outline-info:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent;
}

.btn-outline-info:not(:disabled):not(.disabled).active,
.btn-outline-info:not(:disabled):not(.disabled):active,
.show>.btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(23, 162, 184, .5);
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning.focus,
.btn-outline-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent;
}

.btn-outline-warning:not(:disabled):not(.disabled).active,
.btn-outline-warning:not(:disabled):not(.disabled):active,
.show>.btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5);
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger.focus,
.btn-outline-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent;
}

.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active,
.show>.btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .5);
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:hover {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light.focus,
.btn-outline-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent;
}

.btn-outline-light:not(:disabled):not(.disabled).active,
.btn-outline-light:not(:disabled):not(.disabled):active,
.show>.btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5);
}

.btn-outline-dark {
    color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark.focus,
.btn-outline-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
    color: #343a40;
    background-color: transparent;
}

.btn-outline-dark:not(:disabled):not(.disabled).active,
.btn-outline-dark:not(:disabled):not(.disabled):active,
.show>.btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 58, 64, .5);
}

.btn-link {
    font-weight: 400;
    color: #007bff;
    text-decoration: none;
}

.btn-link:hover {
    color: #0056b3;
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
    text-decoration: underline;
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d;
    pointer-events: none;
}

.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
}

.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem;
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear;
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none;
    }
}

.fade:not(.show) {
    opacity: 0;
}

.collapse:not(.show) {
    display: none;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease;
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none;
    }
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none;
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default;
}

.nav-tabs {
    border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
    color: #6c757d;
    background-color: transparent;
    border-color: transparent;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.nav-pills .nav-link {
    border-radius: .25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #007bff;
}

.nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center;
}

.nav-justified .nav-item,
.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
}

.tab-content>.tab-pane {
    display: none;
}

.tab-content>.active {
    display: block;
}

.navbar {
    position: relative;
    padding: .5rem 1rem;
}

.navbar,
.navbar .container,
.navbar .container-fluid,
.navbar .container-lg,
.navbar .container-md,
.navbar .container-sm,
.navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none;
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none;
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem;
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: 50%/100% 100% no-repeat;
}

.navbar-nav-scroll {
    max-height: 75vh;
    overflow-y: auto;
}

@media (max-width:575.98px) {
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width:576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-sm .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-sm>.container,
    .navbar-expand-sm>.container-fluid,
    .navbar-expand-sm>.container-lg,
    .navbar-expand-sm>.container-md,
    .navbar-expand-sm>.container-sm,
    .navbar-expand-sm>.container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-sm .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }
    .navbar-expand-sm .navbar-toggler {
        display: none;
    }
}

@media (max-width:767.98px) {
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width:768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-md .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid,
    .navbar-expand-md>.container-lg,
    .navbar-expand-md>.container-md,
    .navbar-expand-md>.container-sm,
    .navbar-expand-md>.container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-md .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }
    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

@media (max-width:991.98px) {
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width:992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-lg .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid,
    .navbar-expand-lg>.container-lg,
    .navbar-expand-lg>.container-md,
    .navbar-expand-lg>.container-sm,
    .navbar-expand-lg>.container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-lg .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }
    .navbar-expand-lg .navbar-toggler {
        display: none;
    }
}

@media (max-width:1199.98px) {
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl {
        padding-right: 0;
        padding-left: 0;
    }
}

@media (min-width:1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start;
    }
    .navbar-expand-xl .navbar-nav {
        flex-direction: row;
    }
    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute;
    }
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem;
    }
    .navbar-expand-xl>.container,
    .navbar-expand-xl>.container-fluid,
    .navbar-expand-xl>.container-lg,
    .navbar-expand-xl>.container-md,
    .navbar-expand-xl>.container-sm,
    .navbar-expand-xl>.container-xl {
        flex-wrap: nowrap;
    }
    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible;
    }
    .navbar-expand-xl .navbar-collapse {
        display: flex!important;
        flex-basis: auto;
    }
    .navbar-expand-xl .navbar-toggler {
        display: none;
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
    padding-right: 0;
    padding-left: 0;
}

.navbar-expand .navbar-nav {
    flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute;
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem;
}

.navbar-expand>.container,
.navbar-expand>.container-fluid,
.navbar-expand>.container-lg,
.navbar-expand>.container-md,
.navbar-expand>.container-sm,
.navbar-expand>.container-xl {
    flex-wrap: nowrap;
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible;
}

.navbar-expand .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
}

.navbar-expand .navbar-toggler {
    display: none;
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5);
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7);
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3);
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9);
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1);
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(0,  0,  0,  0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5);
}

.navbar-light .navbar-text a,
.navbar-light .navbar-text a:focus,
.navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9);
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, .5);
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: hsla(0, 0%, 100%, .75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, .25);
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff;
}

.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, .5);
    border-color: hsla(0, 0%, 100%, .1);
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='30' height='30'%3E%3Cpath stroke='rgba(255,  255,  255,  0.5)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, .5);
}

.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:focus,
.navbar-dark .navbar-text a:hover {
    color: #fff;
}

body {
    font-family: Montserrat, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    color: #64656b;
    overflow-x: hidden;
}

body.sidebar-open>.overlay {
    left: 0;
    width: calc(100% - 320px);
}

@media (max-width:767.98px) {
    body.sidebar-open>.overlay {
        width: 0;
    }
}

a,
a:hover {
    text-decoration: none;
}

.list--no-style {
    list-style: none;
    padding: 0;
    margin: 0;
}

body,
html {
    width: 100%;
    height: 100%
}

.col {
    min-width: auto!important;
}

.header {
    background-color: #fcfcfc;
    padding: 100px 0 0px;
}

.header__bg-right {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
}

@media (max-width:1199.98px) {
    .header__bg-right {
        max-width: 70%;
        height: auto;
    }
}

@media (max-width:991.98px) {
    .header__bg-right {
        max-width: 80%
    }
}

@media (max-width:767.98px) {
    .header__bg-right {
        max-width: 35%
    }
}

.header .container {
    position: relative;
    z-index: 3;
}

.header__link {
    font-size: 1rem;
    padding: 0 2.25rem!important;
    display: inline-block;
    color: #fff;
    vertical-align: middle;
}

.header__link:hover {
    color: #fff;
}

.header__link:after {
    content: "";
    position: absolute;
    top: 0;
    background-color: #fff;
    right: 0;
    display: block;
    width: 1px;
    height: 1.5rem;
}

.header__nav-wrap {
    text-align: right;
}

.header__menu {
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;
}

.header__item {
    display: inline-block;
    position: relative;
}

@media (max-width:767.98px) {
    .header__item {
        display: none;
    }
}

.header__item:first-child .header__link {
    padding-left: 0!important;
}

/* @media (max-width:767.98px) {
    .header__item: last-child {
        display: inline-block;
    }
} */

.header__item:last-child .header__link {
    padding: 0!important;
}

.header__item:last-child .header__link:after,
.header__item:nth-last-child(2) .header__link:after {
    display: none;
}

.header__logo {
    display: inline-block;
}

.header__logo img {
    width: 100%;
    max-width: 125px;
}

.header__brand {
    padding: 0;
}

@media (max-width:991.98px) {
    .header__brand {
        margin-top: 0;
        margin-left: 1rem;
    }
}

.nav__hamIcon {
    border-radius: 5px;
    z-index: 2;
    transition: all .1s ease-in-out;
    cursor: pointer;
}

.nav__hamIcon:focus {
    outline: 0;
}

.nav__hamIcon .menu-bar,
.nav__hamIcon:after,
.nav__hamIcon:before {
    content: "";
    height: 2px;
    display: block;
    background: #fff;
    margin: 6px 0 auto auto;
    transition: all .3s ease-in-out;
    border-radius: 4px;
    width: 25px;
}

.nav__hamIcon .menu-bar {
    width: 19px;
}

.nav__hamIcon:before {
    margin-top: 0;
}

.nav__hamIcon--howitworks {
    padding: .75rem 0;
}

.nav__hamIcon--howitworks .menu-bar,
.nav__hamIcon--howitworks:after,
.nav__hamIcon--howitworks:before {
    background-color: #64656b;
    width: 1.375rem;
}

.nav__hamIcon--howitworks .menu-bar,
.nav__hamIcon--howitworks:after {
    margin: 5px 0 auto auto;
}

.toggle-menu {
    position: fixed;
    top: 0;
    left: auto;
    right: -20rem;
    bottom: 0;
    overflow: auto;
    background: #fff;
    padding: 1rem;
    z-index: 9;
    width: 100%;
    max-width: 320px;
    transition: all .25s ease-in-out;
}

.toggle-menu--expanded {
    right: 0;
    box-shadow: 0 0 20px #e6e6e8;
}

@media (max-width:767.98px) {
    .toggle-menu--expanded {
        max-width: 100%
    }
}

.toggle-menu__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: calc(100% - 140px);
    overflow: auto;
}

.toggle-menu__list {
    padding: 0;
    margin: 0;
    list-style: none;
    width: 100%;
    max-width: 100%
}

.toggle-menu__item {
    display: block;
}

.toggle-menu__item:first-child,
.toggle-menu__item:nth-of-type(2),
.toggle-menu__item:nth-of-type(3) {
    display: none;
}
/* 
@media (max-width:767.98px) {
    .toggle-menu__item: first-child,
    .toggle-menu__item:nth-of-type(2),
    .toggle-menu__item:nth-of-type(3) {
        display: block;
    }
} */

.toggle-menu__link {
    color: #035c56;
    font-size: 1.4rem;
    padding: .5rem;
    display: inline-block;
    font-weight: 600;
    position: relative;
}

.toggle-menu__link:hover {
    text-decoration: underline;
    color: #035c56;
}

.close-menu {
    width: 40px;
    height: 40px;
    margin: 0 0 0 auto;
    cursor: pointer;
    display: flex;
    background: transparent;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    transition: all .2s ease-in-out;
}

.close-menu:hover {
    background: #035c56;
}

.close-menu:hover .close-menu__icon,
.close-menu:hover .close-menu__icon:before {
    background-color: #fff;
}

.close-menu__icon {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    position: relative;
}

.close-menu__icon,
.close-menu__icon:before {
    background-color: #035c56;
    width: 25px;
    height: 1.5px;
    display: inline-block;
}

.close-menu__icon:before {
    content: "";
    cursor: pointer;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    position: absolute;
    left: 0;
    top: 0;
}

.social-media__list {
    padding: 0;
    margin: 0;
}

.social-media__item {
    display: inline-block;
    margin-left: 6px;
}

.social-media__link {
    color: #fff;
    padding: 0 .5rem;
    font-size: .85rem;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background: #035c56;
}

.social-media__link:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    color: #fff;
}

.block {
    padding: 4.6875rem 0;
}

.block:first-child {
    background-color: #fcfcfc;
}

.block:first-child>div {
    z-index: 2;
    position: relative;
}

.block--banner {
    margin-top: 0;
    background-color: #fcfcfc;
    padding-top: 1rem;
}

@media (max-width:1199.98px) {
    .block--banner {
        padding-top: 3rem!important;
    }
}

.block--banner .block__title-wrap {
    margin-bottom: 0;
}

.block--banner .block__content-rightmedia {
    overflow: hidden;
}

.block--overflow-hidden {
    overflow: hidden;
    padding-bottom: 5.3rem;
}

.block--subscribe {
    background: linear-gradient(90deg, #0b756e, #035c56);
    color: #fff;
    position: relative;
}

.block--getstarted .workspace__link {
    display: inline-block;
    width: 100%;
    margin-bottom: 1.5rem;
    padding: .8rem;
    border: 1px solid #e6e6e6;
    background-color: #fff;
    position: relative;
    outline: none;
    font-size: .9rem;
    color: #64656b;
    height: 48px;
}

.block--getstarted .workspace__link:hover {
    box-shadow: 0 8px 14px #f0f0f0;
}

.block--getstarted .workspace__link:hover .workspace__link-append {
    right: 15px;
    color: #035c56;
}

.block--getstarted .workspace__link-append {
    position: absolute;
    right: 20px;
    top: 13px;
    transition: .2s ease-in-out;
}

.block--flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

.block--blogs {
    position: relative;
    padding-top: 4.0625rem;
}

@media (max-width:767.98px) {
    .block--mobile-reverse {
        flex-direction: column-reverse;
        padding-top: 4rem;
    }
}

.block--confirm-otp,
.block--find-workspace {
    padding-top: 5rem;
}

.block--contact .info__wrap {
    margin-bottom: 3rem;
}

.block--contact .info__item {
    margin-bottom: 1.5rem;
    display: flex;
    font-weight: 500;
    color: #0d0c38;
}

.block--contact .info__item:before {
    font-family: vreli;
    margin-right: 1rem;
}

.block--contact .location__wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 3px 27px 0 #bdbdbd;
    width: 526px;
    height: 372px;
    background-color: #fff;
    margin-bottom: 2rem;
}

@media (max-width:1199.98px) {
    .block--contact .location__wrap {
        width: 95%
    }
}

@media (max-width:575.98px) {
    .block--contact .location__wrap {
        margin-left: auto;
        margin-right: auto;
    }
}

.block--contact .location__iframe {
    border: 0;
    width: 496px;
    height: 342px;
}

@media (max-width:1199.98px) {
    .block--contact .location__iframe {
        width: 94%;
        height: 342px;
    }
}

.block--split {
    position: relative;
}

.block--split .block__content-right {
    padding-right: calc(50% - 555px);
}

@media (max-width:1199.98px) {
    .block--split .block__content-right {
        padding-right: calc(50% - 465px);
    }
}

@media (max-width:991.98px) {
    .block--split .block__content-right {
        padding-right: calc(50% - 345px);
    }
}

@media (max-width:767.98px) {
    .block--split .block__content-right {
        padding-right: calc(50% - 255px);
        padding-right: 15px;
    }
}

.block--split .block__content-left {
    padding-left: calc(50% - 555px);
}

@media (max-width:1199.98px) {
    .block--split .block__content-left {
        padding-left: calc(50% - 465px);
    }
}

@media (max-width:991.98px) {
    .block--split .block__content-left {
        padding-left: calc(50% - 345px);
    }
}

@media (max-width:767.98px) {
    .block--split .block__content-left {
        padding-left: 15px;
    }
}

.block--split .block__img-wrapper:after {
    content: "";
    width: 300px;
    height: 300px;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    background: #eff5fb;
    opacity: .6;
    right: 38px;
    top: -4rem;
    z-index: -1;
}

/* @media (max-width:575.98px) {
    .block--split .block__img-wrapper: after {
        right: 7px;
    }
} */

.block--confirmotp {
    overflow: hidden;
}

@media (max-width:767.98px) {
    .block--confirmotp .block--mobile-reverse {
        padding-top: 2rem;
    }
}

.block--confirmotp .workspace__imgright-wrap:after {
    content: none;
}

@media (max-width:1199.98px) {
    .block--confirmotp .workspace__imgright-wrap {
        position: unset;
    }
}

.block--confirmotp .workspace-wrap:before {
    content: none;
}

.block--find-workspace .workspace-wrap:before {
    left: 0;
    right: 0;
    top: -125px;
}

.block--find-workspace .workspace__available {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.block--find-workspace .workspace__available-link {
    display: inline-block;
    width: 100%;
    padding: .5rem 1rem;
    color: #035c56;
}

.block--find-workspace .workspace__available-link:hover {
    border: 1px solid #e3e3e3;
    box-shadow: 0 0 30px #f0f0f0;
    margin: -1px 0;
}

.block--find-workspace .workspace__available-link:hover .workspace__begin:after {
    border: 1px solid #f2f2f2;
    background: #f2f2f2;
    -webkit-transform: scale(2);
            transform: scale(2);
    z-index: -2;
    right: 1rem;
    top: .2rem;
    transition: .3s ease-in-out;
}

.block--find-workspace .workspace__available-wrap {
    margin-bottom: 2rem;
}

.block--find-workspace .workspace__name {
    color: #64656b;
}

.block--find-workspace .workspace__name-icon {
    display: inline-block;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid #f2f2f2;
    background: #f2f2f2;
}

.block--find-workspace .workspace__begin {
    position: relative;
    z-index: 1;
}

.block--find-workspace .workspace__begin:after {
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    right: -3rem;
    border-color: #f2f2f2;
}

.block--find-workspace .workspace__begin-text {
    font-size: .8rem;
}

.block--find-workspace .workspace__begin-arrow {
    display: inline-block;
    width: 9px;
    border-top: 1px solid #035c56;
    vertical-align: middle;
    position: relative;
    top: .7rem;
}

.block--find-workspace .workspace__begin-arrow:after {
    content: "";
    width: 5px;
    height: 5px;
    display: inline-block;
    border: 1px solid #035c56;
    border-width: 1px 1px 0 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    left: .2rem;
    position: relative;
    bottom: .95rem;
}

.block--text-only p {
    margin-bottom: 0;
}

.block--text-only .block__content-wrap {
    margin-bottom: 1.5rem;
}

.block--text-only .block__title {
    font-size: 1.5rem;
}

.block--contact,
.block--text-only {
    position: relative;
    z-index: 2;
}

.block__img {
    width: 100%
}

@media (max-width:767.98px) {
    .block__img-right {
        margin-bottom: 5rem;
    }
}

.block__img-left:before {
    content: "";
    width: 100%;
    height: 100%;
    max-width: 700px;
    max-height: 700px;
    border-radius: 50%;
    position: absolute;
    display: inline-block;
    background: #f3f7fc;
    left: 37%;
    bottom: 8%;
    z-index: -2;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

.block--howitworks {
    overflow-x: hidden;
    color: #43454a;
}

.block__title {
    font-size: 2.25rem;
    font-weight: 700;
    color: #0d0c38;
}

@media (max-width:767.98px) {
    .block__title {
        text-align: center;
        font-size: 1.8rem;
    }
}

@media (max-width:575.98px) {
    .block__title {
        font-size: 1.5rem;
    }
}

.block__title--center {
    margin-bottom: 4rem;
    text-align: center;
}

.block__title-wrap {
    margin-bottom: 2rem;
}

@media (max-width:767.98px) {
    .block__title-wrap--sm-hidden {
        display: none;
    }
}

.block__title-wrap--sm-block {
    display: none;
}

@media (max-width:767.98px) {
    .block__title-wrap--sm-block {
        display: block;
    }
}

.block__title--center {
    margin-bottom: 3rem;
}

.block__title-sub {
    font-size: 1.1rem;
}

.block__sub--light,
.block__title--light {
    color: #fff;
}

@media (max-width:767.98px) {
    .block__title+p {
        text-align: center;
    }
}

.block__sub {
    font-size: 1.25rem;
    margin-bottom: .25rem;
    font-weight: 500;
}

@media (max-width:767.98px) {
    .block__sub {
        text-align: center;
    }
}

.block__sub--primary {
    color: #035c56;
}

.block__sub--features {
    margin-bottom: 1.875rem;
}

@media (max-width:767.98px) {
    .block__content-right .block__title-wrap {
        display: none;
    }
}

@media (max-width:767.98px) {
    .block__content-rightmedia {
        margin-bottom: 2rem;
    }
}

.block__content-titlecolor {
    color: #2228a0;
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 2rem;
}

.block__content--center {
    text-align: center;
}

.block__content-form:after {
    content: "";
    position: absolute;
    /* background-image: url("./../images/getStart1.png"); */
    background-repeat: no-repeat;
    background-size: contain;
    width: 100px;
    height: 100px;
    display: inline-block;
    bottom: 6.5rem;
    margin-left: 15px;
    right: -100px;
}

/* @media (max-width:991.98px) {
    .block__content-form: after {
        content: none;
    }
} */

.block__content-wrap b,
.block__content-wrap strong {
    color: #0d0c38;
}

@media (max-width:1199.98px) {
    .block__media {
        max-width: 100%
    }
}

.block__media--top {
    box-shadow: 0 5px 10px -10px #6a5cf1;
}

.block__media-wrap {
    display: inline-block;
}

.block__media-wrap--banner {
    padding: 0 2rem;
}

@media (max-width:991.98px) {
    .block__media-wrap--banner {
        padding: 0 1rem;
    }
}

.block__media--bg-bottom {
    position: absolute;
    left: -85px;
    bottom: -55px;
    z-index: -1;
}

.block__list--lower li {
    list-style: lower-alpha;
}

.block__list--roman li {
    list-style: lower-roman;
}

.block__mb-15 {
    margin-bottom: 1.5rem!important;
}

.block__page-title {
    color: #035c56;
    font-size: 2rem;
}

.block--features {
    background-color: #fcfcfc;
}

.block__bg-icon {
    position: absolute;
    bottom: -62px;
    left: 40%;
    z-index: 2;
}

.block__bg-grid {
    background-image: url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='6' height='6'%3E%3Cg data-name='Group 1' transform='translate(-119 -126)'%3E%3Ccircle data-name='Ellipse 1' cx='1' cy='1' r='1' transform='translate(121 128)' fill='%23d7d9e6'/%3E%3Cpath data-name='Rectangle 2' fill='none' d='M119 126h6v6h-6z'/%3E%3C/g%3E%3C/svg%3E");
    position: absolute;
    width: 200px;
    height: 200px;
    z-index: -1;
}

.block__bg-grid--banner {
    left: -40px;
    bottom: -140px;
}

.block__bg-grid--howitworks {
    left: -85px;
    bottom: -55px;
}

.block__bg-grid--newsletter {
    width: 56px;
    height: 88px;
    right: 0;
    z-index: 0;
}

.block__bg-grid--blogs {
    width: 60px;
    height: 100px;
    bottom: 50px;
    left: 0;
}

.block__gif-wrap {
    min-height: 430px;
    border-radius: 1.5rem 1.5rem 0 0;
    border: solid #43454a;
    border-width: .5rem .5rem 1.25rem;
}

@media (max-width:1199.98px) {
    .block__gif-wrap {
        min-height: 300px;
    }
}

@media (max-width:575.98px) {
    .block__gif-wrap {
        min-height: 225px;
    }
}

.block__gif-wrap--banner {
    background-color: #43454a;
    /* background-image: url("../images/vreli_workflow_new.gif"); */
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%
}

.block__gif-wrap--banner:after {
    content: "";
    width: calc(100% + 4rem);
    /* background: url("../images/laptop_bottom.png") no-repeat; */
    background-size: contain;
    height: 48px;
    bottom: -42px;
    position: absolute;
    left: -2rem;
}

.banner__bg-bottom {
    position: absolute;
    left: -40px;
    bottom: -140px;
    z-index: -1;
}

.banner__content {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

.banner__subtext {
    margin-bottom: 30px;
}

.howitworks__tabs {
    display: none;
}

@media (max-width:767.98px) {
    .howitworks__tabs--hidden {
        background: #ececec;
        padding: 0 1rem;
        color: #64656b;
        position: relative;
        text-transform: uppercase;
    }
}

.howitworks__tab-item,
.howitworks__tabs-wrap {
    margin-bottom: 1rem;
}

.howitworks__tab-item:before {
    content: "";
    display: inline-block;
    width: 7px;
    height: 14px;
    border: solid #64656b;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(44deg);
            transform: rotate(44deg);
    margin-right: 7px;
    margin-left: 5px;
}

.howitworks__dropdown {
    max-height: 0;
    overflow: hidden;
    transition: max-height .2s ease-out;
}

.howitworks__dropdown--active {
    box-shadow: 0 4px 10px -1px;
}

.howitworks__dropdown li {
    flex: 0 0 100%
}

.footer {
    border-top: 1px solid #dcdcdc;
    background-color: #fff;
    z-index: 2;
}

.footer__top {
    padding: 1.5rem 0;
}

@media (max-width:991.98px) {
    .footer__logo {
        margin-bottom: 1rem;
    }
}

.footer__logo-img {
    height: 40px;
}

.footer__menu {
    text-align: center;
    justify-content: space-evenly;
    display: flex;
    flex-wrap: wrap;
}

@media (max-width:991.98px) {
    .footer__menu {
        margin-bottom: 1rem;
    }
}

.footer__menu-item {
    display: inline-block;
}

.footer__menu-link {
    display: block;
    line-height: 40px;
    color: #4b4c51;
    padding: 0 1rem;
}

.footer__menu-link:hover {
    color: #0d0c38;
}

.footer__logo,
.footer__social-list {
    align-items: center;
    display: flex;
}

@media (max-width:991.98px) {
    .footer__logo,
    .footer__social-list {
        display: block;
        width: 100%;
        text-align: center;
    }
}

.footer__social-item {
    display: inline-block;
}

.footer__social-item:not(:last-child) {
    margin-right: .5rem;
}

.footer__social-link {
    width: 32px;
    height: 32px;
    display: block;
    background: #d2d2d2;
    border-radius: 50%;
    color: #fff;
    line-height: 34px;
    text-align: center;
    border: 1px solid #e9e7e7;
}

.footer__social-link:focus,
.footer__social-link:hover {
    color: #fff;
    background-color: #035c56;
    border-color: #035c56;
    outline: 0;
}

.row--footer {
    justify-content: space-between;
}

.form--home {
    position: relative;
    margin-bottom: 5.5rem;
}

@media (max-width:575.98px) {
    .form--home {
        margin-bottom: 2rem;
    }
}

.form__control {
    height: 48px;
    padding: 0 .3rem;
    background: #fafafa;
    border: 1px solid #f2f2f2;
}

.form__control--sm {
    height: 40px;
}

.form__control--whitebg {
    background: #fff;
    border: 1px solid #ececec;
}

.form__control-label {
    top: .75rem;
    left: 1rem;
    color: #919191;
}

.form__control-label,
.form__control-label--floating {
    position: absolute;
    z-index: 1;
    transition: all .25s ease-in-out;
}

.form__control-label--floating {
    padding: 0 .2em;
    top: -.7em;
    left: 1em;
    background-color: #fff;
    color: #035c56;
    font-weight: 600;
    font-size: 15px;
}

.form__control:focus {
    outline: 0;
    /* text-align: center; */
}

.form__input-email {
    width: 100%;
    height: 60px;
    max-width: calc(100% - 140px);
    padding: 0 1.25rem;
    outline: none;
    border: 1px solid #f9f6f6;
    border-radius: 6px 0 0 6px;
}

.form__input-email::-webkit-input-placeholder {
    color: #909298;
}

.form__input-email::placeholder {
    color: #909298;
}

@media (max-width:575.98px) {
    .form__input-email {
        max-width: 100%;
        margin-bottom: 1rem;
        text-align: center;
        border-radius: 6px;
        box-shadow: 0 5px 17px -10px #0b756e;
    }
}

.form__input-submit {
    background-color: #035c56;
    color: #fff;
    padding: 7px 55px;
}

.form__input-submit:focus,
.form__input-submit:hover {
    border-color: #035c56;
    color: #fff;
}

.form__input-submit:focus {
    box-shadow: 0 0 .1rem .2rem #05a69b;
}

.form__input-group--unfocus .form__control-label--floating {
    color: #8a8a8a;
    font-size: 14px;
}

.form__input-group-append {
    background: #575757;
    color: #fff;
    border: 0;
    width: 48px;
    line-height: 1;
    height: 48px;
}

.form__input-group-append--sm {
    height: 40px;
    width: 40px;
}

.form__input-group-append:hover {
    color: #fff;
    background-color: #035c56;
}

.form__input-group-append--whitebg {
    border: 1px solid #ececec;
    background: #fff;
}

.form__input-group-append--whitebg:hover {
    background: #fff;
}

.form__input-group--banner {
    border-radius: 6px;
    box-shadow: 0 5px 17px -10px #0b756e;
    height: 60px;
}

@media (max-width:575.98px) {
    .form__input-group--banner {
        height: unset;
        box-shadow: unset;
    }
}

.form__button {
    background: linear-gradient(#0b756e, #035c56);
    color: #fff;
    height: 60px;
    line-height: 60px;
    border-radius: 0 6px 6px 0;
    width: 100%;
    max-width: 140px;
    font-weight: 600;
    outline: none;
    border: none;
}

.form__button:focus,
.form__button:hover {
    -webkit-transform: scale(1.02);
            transform: scale(1.02);
    outline: 0;
}

@media (max-width:575.98px) {
    .form__button {
        max-width: 100%;
        margin-left: 0;
        border-radius: 6px;
    }
}

.form--workspace {
    margin-bottom: 50px;
}

.form--custom label {
    display: block;
    font-weight: 500;
}

.form--custom .form-control {
    height: 48px;
    border-radius: 0;
}

.form--custom .form-control:focus,
.form--custom .form-control:hover {
    border-color: #035c56;
}

.form--custom .form-control:focus {
    box-shadow: 0 0 0 .1rem #06bfb2;
}

.form--custom .form-group {
    margin-bottom: 2rem;
}

.form--custom textarea.form-control {
    height: auto;
    resize: none;
}

.form--custom .form__input-submit {
    padding: .375rem 1.9rem;
}

.btn-cta {
    background-color: #fff;
    height: 60px;
    font-weight: 600;
    border-radius: 6px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 1rem;
    min-width: 200px;
    border: 1px solid #fff;
    box-shadow: none;
    transition: box-shadow .2s ease-in-out;
}

.btn-cta:focus,
.btn-cta:hover {
    box-shadow: 0 6px 12px 0 #012b28;
}

.btn-cta--outline {
    background-color: transparent;
    color: #fff;
}

.btn-cta--outline:hover {
    color: #fff;
}

@media (max-width:575.98px) {
    .btn-sm-full {
        width: 100%
    }
}

.btn+.btn {
    margin-left: 1.875rem;
}

@media (max-width:1199.98px) {
    .btn+.btn {
        margin-top: 1rem;
        margin-left: 0;
    }
}

@media (max-width:767.98px) {
    .btn+.btn {
        margin-top: 0;
        margin-left: 1.875rem;
    }
}

@media (max-width:575.98px) {
    .btn+.btn {
        margin-top: 1rem;
        margin-left: 0;
    }
}

.workspace-wrap {
    background-color: #fff;
    padding: 2.5rem;
    border: 1px solid #ececec;
    box-shadow: 0 20px 30px -12px #fff;
    position: relative;
    -webkit-box-shadow: 0 20px 30px -12px #fff;
}

@media (max-width:767.98px) {
    .workspace-wrap {
        padding: 1.5rem;
    }
}

.workspace-wrap:before {
    content: "";
    display: block;
    position: absolute;
    left: -92px;
    top: -64px;
    margin: auto;
    width: 280px;
    height: 170px;
    /* background-image: url("../images/dot-final.png"); */
    z-index: -1;
    background-size: 3%;
    opacity: .3;
}

.workspace-wrap--input .form__input-group {
    margin: 3.3rem 0;
}

.workspace-wrap--input .form__control-text {
    font-size: .9rem;
    display: inline-block;
    align-self: flex-end;
}

@media (max-width:575.98px) {
    .workspace-wrap--input .form__control-text {
        position: absolute;
        top: 3.1rem;
        padding-left: .5rem;
    }
}

.workspace__title {
    font-size: 1.5rem;
    font-weight: 600;
    color: #035c56;
    margin-bottom: 1rem;
    /* text-align: center; */
}

.workspace__content {
    font-size: .9rem;
    margin-bottom: 2rem;
    /* text-align: center; */
}

.workspace__content-wrap {
    margin-bottom: 3rem;
}

.workspace-url__wrap {
    position: absolute;
    background-color: #ececec;
    width: 50rem;
    height: 23px;
    top: 2%;
    left: 13%;
    border-radius: 10px;
    padding-left: 9px;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (max-width:1199.98px) {
    .workspace-url__wrap {
        width: 45.5rem;
        left: 4.5rem;
    }
}

@media (max-width:767.98px) {
    .workspace-url__wrap {
        z-index: 2;
        width: 80%;
        left: 3.5rem;
        top: .2rem;
    }
}

@media (max-width:575.98px) {
    .workspace-url__wrap {
        width: 82%;
        left: 7%;
        font-size: .8rem;
    }
}

@media (max-width:1199.98px) {
    .workspace__img-laptop {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width:767.98px) {
    .workspace__img-laptop {
        width: 90%
    }
}

.workspace__img-mobile {
    position: absolute;
    top: 30%;
    left: -11%
}

@media (max-width:1199.98px) {
    .workspace__img-mobile {
        max-height: 100%;
        top: 10%
    }
}

@media (max-width:767.98px) {
    .workspace__img-mobile {
        top: 3%;
        left: -6%
    }
}

.workspace__img-mail {
    max-width: 100%;
    height: auto;
}

.workspace__imgright-wrap {
    position: relative;
    left: 6rem;
}

@media (max-width:1199.98px) {
    .workspace__imgright-wrap {
        left: 2rem;
    }
}

.workspace__imgright-wrap:after {
    content: "";
    display: block;
    position: absolute;
    left: 9rem;
    right: 0;
    top: -43px;
    bottom: -115px;
    margin: auto;
    width: 695px;
    height: auto;
    background: linear-gradient(#0b756e, #035c56);
    z-index: -2;
}
/* 
@media (max-width:1199.98px) {
    .workspace__imgright-wrap: after {
        left: 2rem;
        top: -25px;
        bottom: -20px;
    }
} */

.workspace-otp__wrap {
    display: flex;
    justify-content: space-evenly;
}

.workspace-otp__verify-wrap {
    margin-top: 1rem;
    font-size: .9rem;
}

.workspace__inline-input {
    width: 20%;
    height: 3rem;
    text-align: center;
    /* border: 1px solid #fff; */
    border: 1px solid #d0c9cb;
    color: #2228a0;
    font-weight: 500;
    margin-right: .5rem;
    font-size: 26px;
}

.workspace__inline-input:hover {
    border-color: #2228a0;
}

.workspace__inline-input__separator {
    color: #fff;
    margin: .7rem .5rem 0 0;
}

.workspace__bottom-wrap {
    font-size: .9rem;
    text-align: center;
}

.current-user {
    position: relative;
    top: -30px;
    left: 15px;
}

.current-user__wrap {
    position: absolute;
    width: 210px;
    height: 90px;
    top: 50%;
    left: -8%;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0 10px 20px 0 #cccccf;
    z-index: 2;
}

@media (max-width:991.98px) {
    .current-user__wrap {
        left: -2rem;
    }
}

@media (max-width:575.98px) {
    .current-user__wrap {
        top: 27%;
        -webkit-transform: scale(.82);
                transform: scale(.82);
    }
}

.current-user__img {
    width: 50px;
    border-radius: 50%;
    border: 1px solid #fff;
    background: #fff;
    box-shadow: 0 5px 20px #fff;
    height: 50px;
}

.current-user__email {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: .95rem;
}

.current-user__workspace {
    font-size: .8rem;
    color: #035c56;
    font-weight: 700;
}

.current-user__details {
    width: 40%;
    padding: .5rem 0;
    background: #f3f3f4;
    border: 1px solid #f3f3f4;
    position: relative;
    border-radius: 10px;
    margin-bottom: .5rem;
}

.current-user__details-fetch {
    font-size: .9rem;
    margin-left: -.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.current-user__details-input {
    display: flex;
}

.current-user__details:first-child {
    width: 58%
}

.current-user__details-wrap {
    margin-top: -1rem;
    margin-left: 1rem;
}

.current-user__details-wrap:after {
    content: "";
    position: absolute;
    top: 31px;
    bottom: 100%;
    right: -11px;
    border-color: #fff transparent;
    border-style: solid;
    border-width: 8px 8px 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
}

.current-user__domain {
    font-size: .8rem;
    color: #035c56;
    font-weight: 700;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;
}

.row--workspace {
    margin: 8rem 0 2rem;
}

.flash-message {
    margin-top: 10px;
}

.flash-message .alert.alert-danger {
    color: #fff;
    background-color: #fc6155;
    border-color: #fc6155;
    font-size: .9rem;
}

.flash-message .alert.alert-danger .close {
    color: #e71404;
}

.flash-message .alert.alert-danger .close:focus,
.flash-message .alert.alert-danger .close:hover {
    color: #830c02;
}

.flash-message .close {
    position: absolute;
    top: 5px;
    right: 5px;
    text-shadow: unset;
    font-size: 1.5rem;
}

.accordion__title {
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 2rem;
    cursor: pointer;
    display: block;
    padding: 1rem 1.25rem;
}

.accordion__item {
    border-left: 5px solid transparent;
    position: relative;
}

.accordion__item:hover {
    background-color: #fcfcfc;
}

.accordion__item.show {
    border-color: #035c56;
    box-shadow: 0 5px 12px -9px #035c56;
    background-color: #fff;
    z-index: 1;
    padding: 1rem 1.25rem;
}

.accordion__item.show .accordion__title {
    padding: 0;
}

.nav-link {
    color: #2228a0;
    font-weight: 600;
}

@media (min-width:768px) and (max-width:991.98px) {
    .nav-link {
        padding: .5rem .8rem;
    }
}

.nav-link--features {
    font-weight: 400;
    border-bottom: 5px solid trahnsparent;
    height: 100%
}

.nav-img {
    margin-bottom: .375rem;
    max-height: 64px;
}

.nav-item .nav-link--howitworks {
    border-radius: 0;
}

.nav-item .nav-link--howitworks.active {
    color: #fff;
    background: linear-gradient(#0b756e, #035c56);
}

.nav-item .nav-link--features {
    color: #bdbfc8;
    background-color: #fff;
    border-radius: 6px;
    padding: .75rem 1rem;
}

@media (max-width:767.98px) {
    .nav-item .nav-link--features {
        padding: .5rem 1rem;
    }
}

.nav-item .nav-link--features.active {
    color: #43454a;
    border-radius: 6px 6px 0 0;
}

.nav-item .nav-link--agile.active {
    border-color: #262ea3;
    background-color: #f2f2f5;
}

.nav-item .nav-link--agile.active .nav-img .darken {
    stroke: #43454a;
}

.nav-item .nav-link--agile.active .nav-img .colored {
    fill: url(#MyGradient);
}

.nav-item .nav-link--product.active {
    border-color: #f9d423;
    background-color: #fefae5;
}

.nav-item .nav-link--product.active .nav-img circle,
.nav-item .nav-link--product.active .nav-img path,
.nav-item .nav-link--product.active .nav-img rect {
    stroke: #43454a;
}

.nav-item .nav-link--product.active .nav-img .colored {
    fill: #f9d423;
    stroke: #f9d423;
}

.nav-item .nav-link--product.active .nav-img ellipse.colored {
    stroke: #43454a;
}

.nav-item .nav-link--ease.active {
    border-color: #35c2fe;
    background-color: #f6feff;
}

.nav-item .nav-link--ease.active .nav-img circle,
.nav-item .nav-link--ease.active .nav-img path,
.nav-item .nav-link--ease.active .nav-img rect {
    stroke: #43454a;
}

.nav-item .nav-link--ease.active .nav-img .colored {
    fill: #29cdfe;
}

.nav-item .nav-link--plug.active {
    border-color: #ff80b8;
    background-color: #ffeff6;
}

.nav-item .nav-link--plug.active .nav-img .darken {
    stroke: #43454a;
}

.nav-item .nav-link--plug.active .nav-img .colored {
    fill: #ff6bad;
    stroke: #ff6bad;
}

.nav-item--features {
    flex: 0 0 25%;
    text-align: center;
}

@media (max-width:991.98px) {
    .nav-item--features {
        flex: 0 0 50%
    }
}

.nav-item--howitworks {
    flex: 0 0 100%
}

.nav--features {
    margin-bottom: 2rem;
    box-shadow: 0 9px 13px -4px #ececec;
}

.tab-pane--features {
    padding: 3rem;
    width: 100%
}

@media (max-width:767.98px) {
    .tab-pane--features {
        padding: 2rem 1rem;
    }
}

.tab-pane--agile {
    background-image: linear-gradient(180deg, rgba(106, 92, 241, .9019607843137255), rgba(52, 76, 212, .9019607843137255)), url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='8' height='8'%3E%3Cg data-name='Group 1'%3E%3Cpath data-name='Rectangle 3' fill='%23fff' d='M0 0h8v8H0z'/%3E%3Cg data-name='Ellipse 1' transform='translate(2 2)' fill='%23344cd4' stroke='%23344cd4'%3E%3Ccircle cx='2' cy='2' r='2' stroke='none'/%3E%3Ccircle cx='2' cy='2' r='1.5' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.tab-pane--agile .btn-cta:focus,
.tab-pane--agile .btn-cta:hover {
    box-shadow: 0 6px 12px 0 #253ab0;
}

.tab-pane--product {
    background-image: linear-gradient(180deg, rgba(254, 239, 165, .9019607843137255), rgba(249, 212, 36, .9019607843137255)), url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='8' height='8'%3E%3Cg data-name='Group 1'%3E%3Cpath data-name='Rectangle 3' fill='%23fff' d='M0 0h8v8H0z'/%3E%3Cg data-name='Ellipse 1' transform='translate(2 2)' fill='%23f9d424' stroke='%23f9d424'%3E%3Ccircle cx='2' cy='2' r='2' stroke='none'/%3E%3Ccircle cx='2' cy='2' r='1.5' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.tab-pane--product .btn-cta:focus,
.tab-pane--product .btn-cta:hover {
    box-shadow: 0 6px 12px 0 #e4bd06;
}

.tab-pane--ease {
    background-image: linear-gradient(170deg, rgba(0, 242, 254, .9019607843137255), rgba(79, 172, 254, .9019607843137255)), url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='8' height='8'%3E%3Cg data-name='Group 1'%3E%3Cpath data-name='Rectangle 3' fill='%23fff' d='M0 0h8v8H0z'/%3E%3Cg data-name='Ellipse 1' transform='translate(2 2)' fill='%234facfe' stroke='%234facfe'%3E%3Ccircle cx='2' cy='2' r='2' stroke='none'/%3E%3Ccircle cx='2' cy='2' r='1.5' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.tab-pane--ease .btn-cta:focus,
.tab-pane--ease .btn-cta:hover {
    box-shadow: 0 6px 12px 0 #00c1cb;
}

.tab-pane--plug {
    background-image: linear-gradient(180deg, rgba(255, 158, 201, .9019607843137255), rgba(255, 104, 171, .9019607843137255)), url("data:image/svg+xml;  charset=utf-8, %3Csvg xmlns='http: //www.w3.org/2000/svg' width='8' height='8'%3E%3Cg data-name='Group 1'%3E%3Cpath data-name='Rectangle 3' fill='%23fff' d='M0 0h8v8H0z'/%3E%3Cg data-name='Ellipse 1' transform='translate(2 2)' fill='%23ff68ab' stroke='%23ff68ab'%3E%3Ccircle cx='2' cy='2' r='2' stroke='none'/%3E%3Ccircle cx='2' cy='2' r='1.5' fill='none'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.tab-pane--plug .btn-cta:focus,
.tab-pane--plug .btn-cta:hover {
    box-shadow: 0 6px 12px 0 #ff529e;
}

@media (max-width:991.98px) {
    .tab__title-wrap {
        text-align: center;
        margin-bottom: 1rem;
    }
}

.tab__sub--light,
.tab__title--light {
    color: #fff;
}

.card__icon {
    width: 3rem;
    height: 3rem;
}

.card__icon-wrap {
    background-color: #fffadf;
    border-radius: 50%;
    margin-bottom: 1rem;
    padding: 1.125rem;
}

.card__icon-wrap--agile {
    background-color: #f2f2f5;
}

.card__icon-wrap--product {
    background-color: #fffadf;
}

.card__icon-wrap--easeToUse {
    background-color: #f6feff;
}

.card__icon-wrap--plug {
    background-color: #ffeff6;
}

.card-deck--features:nth-child(2) {
    margin-top: 1.875rem;
}

.card--features {
    padding: 1.125rem;
    text-align: center;
    border-radius: 6px;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

@media (max-width:575.98px) {
    .card--features {
        padding: 1rem;
    }
}

.card--features p {
    margin-bottom: 0;
}

.card--features:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.card--blog,
.card--features {
    background: #fff;
}

.card--blog {
    border: 1px solid #dfe6fe;
    border-radius: 6px 6px 0 0;
}

.card__wrap--features:first-child {
    margin-bottom: 1.875rem;
}

/* @media (max-width:991.98px) {
    .card__wrap--blog: not(:last-child) {
        margin-bottom: 1.5rem;
    }
} */

/* @media (max-width:767.98px) {
    .card__wrap--blog {
        text-align: center;
    }
} */

.card__img {
    height: 175px;
    display: block;
    border-radius: 6px 6px 0 0;
    max-width: 100%;
    width: 100%;
    object-fit: cover;
}

@media (max-width:767.98px) {
    .card__img {
        height: auto;
        max-height: 300px;
    }
}

.card__title {
    margin-bottom: .5rem;
    font-weight: 600;
    color: #0d0c38;
}

.card__title--blog {
    -webkit-line-clamp: 2;
    color: #0d0c38;
    min-height: 3rem;
}

.card__read-more a {
    color: #f5ce19;
    font-weight: 600;
}

.card__read-more a span {
    vertical-align: middle;
}

.card__read-more a .icon {
    margin-left: .25rem;
    display: inline-block;
    transition: -webkit-transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
}

.card__read-more a:focus,
.card__read-more a:hover {
    outline: 0;
}

.card__read-more a:focus span:first-child,
.card__read-more a:hover span:first-child {
    border-bottom: 2px solid;
}

.card__read-more a:focus .icon,
.card__read-more a:hover .icon {
    -webkit-transform: translateX(10px);
            transform: translateX(10px);
}

.card__content-wrap {
    border-radius: 0 0 6px 6px;
    padding: 1.25rem;
}

.card__content--blog p {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 72px;
}

.owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    position: relative;
    z-index: 1;
}

/* .owl-carousel .owl-stage {
    position: relative;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
} */

.owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
}

/* .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    -webkit-transform: translateZ(0);
} */

.owl-carousel .owl-item,
/* .owl-carousel .owl-wrapper {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
} */

/* .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
} */

.owl-carousel .owl-item img {
    display: block;
    width: 100%
}

.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav.disabled {
    display: none;
}

.owl-carousel .owl-dot,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-nav .owl-prev {
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0!important;
    font: inherit;
}

.owl-carousel.owl-loaded {
    display: block;
}

.owl-carousel.owl-loading {
    opacity: 0;
    display: block;
}

.owl-carousel.owl-hidden {
    opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    user-select: none;
}

.owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
}

.owl-carousel.owl-rtl {
    direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
    float: right;
}

.no-js .owl-carousel {
    display: block;
}

.owl-carousel .animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
    z-index: 0;
}

.owl-carousel .owl-animated-out {
    z-index: 1;
}

.owl-carousel .fadeOut {
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
}

@-webkit-keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

.owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity .4s ease;
}

.owl-carousel .owl-item .owl-lazy:not([src]),
.owl-carousel .owl-item .owl-lazy[src^=""] {
    max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
}

.owl-nav {
    text-align: center;
}

.owl-nav button {
    height: 40px;
    width: 40px;
    display: inline-flex!important;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc!important;
    border-radius: 6px;
    color: #666!important;
    position: relative;
}

.owl-nav button:before {
    content: "\E908";
    font-family: vreli;
    /* speak: none; */
    font-style: normal;
    font-weight: 400;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.owl-nav button:focus,
.owl-nav button:hover {
    background: transparent!important;
    color: #035c56!important;
    outline: 0;
}

.owl-nav button+button {
    margin-left: .5rem;
}

.owl-nav button span {
    display: none;
}

.owl-nav button.owl-prev:before {
    -webkit-transform: translate(-50%, -50%) rotate(180deg);
            transform: translate(-50%, -50%) rotate(180deg);
}

.owl-stage-outer {
    margin-bottom: 2rem;
}

.owl-item {
    padding: 0 15px!important;
}

.form__input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.form__control {
    position: relative;
    flex: 1 1;
    min-width: 0;
    margin-bottom: 0;
}

.btn--primary-outline {
    text-transform: uppercase;
    font-size: .85rem;
    background: #fff;
    font-weight: 600;
    font-style: normal;
}

.btn--outline {
    border: 1px solid #035c56;
}

.ml-auto {
    margin-left: auto;
}

.icon-animated {
    position: relative;
}

.icon.icon-email:before {
    font-size: 1.2rem;
    line-height: 48px;
    color: #035c56;
}

.icon-location:before,
.icon-phone:before {
    font-size: 1.4rem;
}

.hollowcircle-icon {
    position: absolute;
    width: 124px;
    height: 124px;
    border: 1rem solid #fff;
    border-radius: 50%;
    background: transparent;
    opacity: .05;
}

.newtons-cradle {
    width: 1.6em;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin-left: 5px;
}

.newtons-cradle div {
    width: .3em;
    height: .3em;
    border-radius: 50%;
    background-color: #2228a0;
}

.newtons-cradle div:first-of-type {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-animation: left-swing .5s ease-in infinite alternate;
    animation: left-swing .5s ease-in infinite alternate;
}

.newtons-cradle div:nth-of-type(3) {
    -webkit-transform: translateX(-95%);
            transform: translateX(-95%);
    -webkit-animation: right-swing .5s ease-out infinite alternate;
    animation: right-swing .5s ease-out infinite alternate;
}

.newtons-cradle--lg {
    width: 6.5em;
}

.newtons-cradle--lg div {
    width: 1.3em;
    height: 1.3em;
}

@-webkit-keyframes left-swing {
    50%,
    to {
        -webkit-transform: translateX(95%);
                transform: translateX(95%);
    }
}

@keyframes left-swing {
    50%,
    to {
        -webkit-transform: translateX(95%);
                transform: translateX(95%);
    }
}

@-webkit-keyframes right-swing {
    50% {
        -webkit-transform: translateX(-95%);
                transform: translateX(-95%);
    }
    to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

@keyframes right-swing {
    50% {
        -webkit-transform: translateX(-95%);
                transform: translateX(-95%);
    }
    to {
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
    }
}

.blink-ripple {
    position: absolute;
    border: 4px solid #035c56;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: blink-ripple 2s cubic-bezier(0, 1, 1, 1) infinite;
    animation: blink-ripple 2s cubic-bezier(0, 1, 1, 1) infinite;
    -webkit-animation-delay: .5s;
    animation-delay: .5s;
}

.blink-ripple__wrapper {
    display: inline-block;
    position: absolute;
    width: 64px;
    height: 64px;
    top: 6px;
    right: -66px;
}

.blink-ripple__wrapper:after,
.blink-ripple__wrapper:before {
    content: "";
    position: absolute;
    border: 4px solid #035c56;
    opacity: 1;
    border-radius: 50%;
    -webkit-animation: blink-ripple 2s cubic-bezier(0, 1, 1, 1) infinite;
    animation: blink-ripple 2s cubic-bezier(0, 1, 1, 1) infinite;
}

.blink-ripple__wrapper:before {
    -webkit-animation: none;
    animation: none;
    top: 24px;
    left: 24px;
    box-shadow: 0 0 8px #035c56;
}

@-webkit-keyframes blink-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    to {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

@keyframes blink-ripple {
    0% {
        top: 28px;
        left: 28px;
        width: 0;
        height: 0;
        opacity: 1;
    }
    to {
        top: -1px;
        left: -1px;
        width: 58px;
        height: 58px;
        opacity: 0;
    }
}

.footer {
    margin-top: auto;
}

.icon {
    font-family: vreli;
    /* speak: none; */
    font-style: normal;
    font-weight: 400;
    -webkit-font-feature-settings: normal;
            font-feature-settings: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-search:before {
    content: "\F002"
}

.icon-external-link:before {
    content: "\F08E"
}

.icon-arrow-left:before {
    content: "\E940"
}

.icon-arrow-right:before {
    content: "\E941"
}

.icon-th-large:before {
    content: "\F009"
}

.icon-sitemap:before {
    content: "\F0E8"
}

.icon-download:before {
    content: "\E93A"
}

.icon-check:before {
    content: "\F00C"
}

.icon-times:before {
    content: "\F00D"
}

.icon-refresh:before {
    content: "\F021"
}

.icon-lock:before {
    content: "\F023"
}

.icon-book:before {
    content: "\F02D"
}

.icon-bold:before {
    content: "\F032"
}

.icon-italic:before {
    content: "\F033"
}

.icon-align-left:before {
    content: "\F036"
}

.icon-align-center:before {
    content: "\F037"
}

.icon-align-right:before {
    content: "\F038"
}

.icon-align-justify:before {
    content: "\F039"
}

.icon-pencil:before {
    content: "\F040"
}

.icon-ban:before {
    content: "\F05E"
}

.icon-exclamation-triangle:before,
.icon-warning:before {
    content: "\F071"
}

.icon-arrows-both:before {
    content: "\F07D"
}

.icon-chart-bar:before {
    content: "\F080"
}

.icon-comments:before {
    content: "\F086"
}

.icon-sign-out:before {
    content: "\F08B"
}

.icon-tasks:before {
    content: "\F0AE"
}

.icon-filter1:before {
    content: "\F0B0"
}

.icon-link:before {
    content: "\F0C1"
}

.icon-save:before {
    content: "\F0C7"
}

.icon-bars:before {
    content: "\F0C9"
}

.icon-list-ul:before {
    content: "\F0CA"
}

.icon-list-ol:before {
    content: "\F0CB"
}

.icon-strikethrough:before {
    content: "\F0CC"
}

.icon-underline:before {
    content: "\F0CD"
}

.icon-columns:before {
    content: "\F0DB"
}

.icon-exchange:before {
    content: "\F0EC"
}

.icon-angle-left:before {
    content: "\F104"
}

.icon-angle-right:before {
    content: "\F105"
}

.icon-angle-up:before {
    content: "\F106"
}

.icon-angle-down:before {
    content: "\F107"
}

.icon-quote-left:before {
    content: "\F10D"
}

.icon-unlink:before {
    content: "\F127"
}

.icon-exclamation:before {
    content: "\F12A"
}

.icon-calendar:before {
    content: "\F133"
}

.icon-unlock:before {
    content: "\F13E"
}

.icon-ellipsis:before {
    content: "\E90A"
}

.icon-file:before {
    content: "\F15B"
}

.icon-long-arrow-down:before {
    content: "\F175"
}

.icon-long-arrow-up:before {
    content: "\F176"
}

.icon-long-arrow-left:before {
    content: "\F177"
}

.icon-long-arrow-right:before {
    content: "\F178"
}

.icon-bug:before {
    content: "\F188"
}

.icon-user-plus:before {
    content: "\F234"
}

.icon-user-times:before {
    content: "\F235"
}

.icon-clone:before {
    content: "\F24D"
}

.icon-stack:before {
    content: "\E93F"
}

.icon-sphere:before {
    content: "\E9C9"
}

.icon-attachment:before {
    content: "\E9CD"
}

.icon-exit:before {
    content: "\EA14"
}

.icon-comment:before {
    content: "\E920"
}

.icon-plus:before {
    content: "\E93B"
}

.icon-info:before {
    content: "\E93C"
}

.icon-menu:before {
    content: "\E93D"
}

.icon-bookmark:before {
    content: "\E93E"
}

.icon-checkmark:before {
    content: "\E939"
}

.icon-about:before {
    content: "\E93D";
    color: #035c56;
}

.icon-action:before {
    content: "\E913";
    color: #035c56;
}

.icon-activitylogs:before {
    content: "\E914"
}

.icon-appicon:before {
    content: "\E915";
    color: #035c56;
}

.icon-backlog:before {
    content: "\E916";
    color: #035c56;
}

.icon-bucket:before {
    content: "\E917";
    color: #035c56;
}

.icon-ccb:before {
    content: "\E918";
    color: #035c56;
}

.icon-clock:before {
    content: "\E919";
    color: #035c56;
}

.icon-copy:before {
    content: "\E91A";
    color: #035c56;
}

.icon-dashboard:before {
    content: "\E91B";
    color: #035c56;
}

.icon-delete:before {
    content: "\E91C"
}

.icon-disabled:before {
    content: "\E91D";
    color: #035c56;
}

.icon-enable:before {
    content: "\E91E";
    color: #035c56;
}

.icon-eye:before {
    content: "\E91F"
}

.icon-features:before {
    content: "\E921";
    color: #035c56;
}

.icon-filter:before {
    content: "\E922";
    color: #035c56;
}

.icon-goals:before {
    content: "\E923";
    color: #035c56;
}

.icon-hash:before {
    content: "\E924";
    color: #035c56;
}

.icon-help:before {
    content: "\E925";
    color: #035c56;
}

.icon-movesprint:before {
    content: "\E926";
    color: #035c56;
}

.icon-notification:before {
    content: "\E927";
    color: #035c56;
}

.icon-progress:before {
    content: "\E928";
    color: #035c56;
}

.icon-quick_link:before {
    content: "\E929";
    color: #035c56;
}

.icon-releases:before {
    content: "\E92A";
    color: #035c56;
}

.icon-report:before {
    content: "\E92B";
    color: #035c56;
}

.icon-requirement:before {
    content: "\E92C";
    color: #035c56;
}

.icon-setting:before {
    content: "\E92D";
    color: #035c56;
}

.icon-size:before {
    content: "\E92E";
    color: #035c56;
}

.icon-slack:before {
    content: "\E92F";
    color: #035c56;
}

.icon-sprint:before {
    content: "\E930";
    color: #035c56;
}

.icon-status:before {
    content: "\E931";
    color: #035c56;
}

.icon-team:before {
    content: "\E932";
    color: #035c56;
}

.icon-ticket:before {
    content: "\E933";
    color: #035c56;
}

.icon-upload:before {
    content: "\E934"
}

.icon-user:before {
    content: "\E935"
}

.icon-usereye:before {
    content: "\E936";
    color: #035c56;
}

.icon-username:before {
    content: "\E937";
    color: #035c56;
}

.icon-workflow1:before {
    content: "\E938";
    color: #035c56;
}

.icon-chevron-right:before {
    content: "\E908"
}

.icon-mail:before {
    content: "\E911";
    color: #035c56;
}

.icon-activity-tracker:before {
    content: "\E905";
    color: #035c56;
}

.icon-dashboard-customization:before {
    content: "\E906";
    color: #035c56;
}

.icon-dollar:before {
    content: "\E907";
    color: #e1e1e1;
}

.icon-hashtag-linking:before {
    content: "\E90B";
    color: #035c56;
}

.icon-invite:before {
    content: "\E90C";
    color: #035c56;
}

.icon-location:before {
    content: "\E90D";
    color: #035c56;
}

.icon-email:before {
    content: "\E90E";
    color: #cc0a0a;
}

.icon-notification-alert:before {
    content: "\E90F";
    color: #035c56;
}

.icon-phone:before {
    content: "\E912";
    color: #035c56;
}

.icon-workflow:before {
    content: "\E910";
    color: #035c56;
}

.icon-dollar1:before {
    content: "\E909"
}

.icon-send:before {
    content: "\E904"
}

.icon-fb:before {
    content: "\E901"
}

.icon-linkedin:before {
    content: "\E902"
}

.icon-right_arrow:before {
    content: "\E900"
}

.icon-twitter:before {
    content: "\E903"
}

.icon-minus:before {
    content: "\F068"
}

.icon-approve:before {
    content: "\E943";
    color: #2eb872;
}

.icon-reject:before {
    content: "\E944";
    color: #fa4659;
}

.icon-notify:before {
    content: "\E942";
    color: #88888a;
}

.icon-pending:before {
    content: "\E943"
}

.icon-submitted:before {
    content: "\E944"
}

.icon-approved:before {
    content: "\E94A"
}

.icon-timesheet:before {
    content: "\E945";
    color: #fff;
}

.icon-timeoff:before {
    content: "\E946"
}

.m-0 {
    margin: 0;
}

.pos--relative {
    position: relative;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-col {
    flex-direction: column;
}

@media (max-width:767.98px) {
    .flex-sm-reverse {
        flex-direction: column-reverse;
    }
}

@media (max-width:767.98px) {
    .d-sm-block {
        display: block;
    }
}

@media (max-width:767.98px) {
    .d-sm-hidden {
        display: none;
    }
}

.w-100 {
    width: 100%
}

.h-100 {
    height: 100%
}

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-between {
    justify-content: space-between;
}

.justify-around {
    justify-content: space-around;
}

.justify-evenly {
    justify-content: space-evenly;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.align-stretch {
    align-items: stretch;
}

.align-center {
    align-items: center;
}

.text-clamp {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.text-center {
    text-align: center;
}

.overlay {
    background-color: rgba(0, 0, 0, .3);
    z-index: 10;
    position: fixed;
    top: 0;
    left: -20rem;
    height: 100%;
    transition: all .25s ease-in-out;
}

.badge--highlighted {
    display: inline-block;
    height: 22px;
    line-height: 22px;
    position: relative;
    padding: 0 10px 0 8px;
    background: #f8fffe;
    border-radius: 4px 0 0 4px;
    font-size: 12px;
    color: #0d0c38;
    border: 1.5px solid #0b756e;
    border-right-width: 0;
    box-sizing: content-box;
}

.badge--highlighted-wrap {
    overflow: hidden;
    margin-bottom: 10px;
    display: flex;
}

.badge--highlighted .punch-hole {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    right: -2px;
    z-index: 2;
    border: 1px solid #0d0c38;
}

.badge--highlighted:after,
.badge--highlighted:before {
    content: "";
    position: absolute;
    top: 0;
    right: -28px;
    bottom: 0;
    margin: auto 0;
    height: 0;
    width: 0;
    border: 14px solid transparent;
    border-right-color: #0b756e;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}

.badge--highlighted:after {
    border: 12px solid transparent;
    border-right-color: #f8fffe;
    z-index: 1;
    right: -24px;
}
